import React, { useEffect, useState } from "react";
import { OSInput } from "../../Components/Input/OSInput";
import { OSButton } from "../../Components/Button/OSButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../store/api";

export const ModificationStock = () => {
    const location = useLocation()
    const selectedItem = location.state.selectedItem
    const [quantite, setQuantite] = useState("");
    const navigate = useNavigate();

    const ModifyStock = async (e) => {
        e.preventDefault();
        try {
            await api.put(`/Stock/${selectedItem.stock_id}`, {
                quantite: quantite,
            });
            navigate(`/Stock`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="w-4/5 flex flex-col items-center justify-center">
            <div className="w-full h-1/6 py-3">
                <p className="text-3xl text-blue-950">Nouveau Stock</p>
            </div>
            <form onSubmit={ModifyStock} className="w-2/3 rounded shadow-lg p-5 flex flex-col justify-center border border-gray-200">
                <div>
                    <OSInput
                        type="number"
                        placeholder="Quantité"
                        onChange={(e) => setQuantite(e.target.value)}
                        defaultValue={selectedItem.quantite}
                    />
                </div>
                <div className="flex justify-between items-center mt-16">
                    <button onClick={() => navigate('/Stock')} className="text-light bg-red-600 focus:ring-4 focus:ring-secondary font-medium rounded text-sm w-24 px-5 py-2 me-2 mb-2 focus:outline-none">Annuler</button>
                    <OSButton value="Modifier" />
                </div>
            </form>
        </div>
    );
};
