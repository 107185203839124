import { create } from "zustand";
import axios from "axios";
import { persist, createJSONStorage } from 'zustand/middleware';
import api from "./api";
import { Navigate, useNavigate } from "react-router-dom";

const createSelectors = (_store) => {
    let store = _store;
    store.use = {};
    for (let k of Object.keys(store.getState())) {
        store.use[k] = () => store((s) => s[k]);
    }
    return store;
};

export const useAppStore = createSelectors(
    create(
        persist(
            (set, get) => ({
                type: "",
                user: "",
                token: "",

                async register(email, password, name) {
                    try {
                        const response = await api.post(
                            `/utilisateur/register`,
                            {
                                email,
                                motDePasse: password,
                                nom: name,
                            }
                        );
                        const { token } = response.data;

                        if (token) {
                            set({ token });
                            return { message: token, success: true };
                        }
                        return { message: "Erreur d'inscription", success: false };
                    } catch (error) {
                        console.log(error);
                        return { message: error.message, success: false };
                    }
                },

                async login(email, password) {
                    try {
                        const response = await api.post(
                            `/utilisateur`,
                            {
                                email,
                                motDePasse: password,
                            }
                        );
                        const { token, userType } = response.data;

                        if (token) {
                            set({ token: token, type: userType });
                            localStorage.setItem("token", token);
                            return { success: true, userType };
                        }
                        return { success: false, status: response.status, error: "Erreur de connexion" };
                    } catch (error) {
                        return { success: false, error: error.message, status: error.response?.status };
                    }
                },

                async logout() {
                    localStorage.clear();
                    sessionStorage.clear();
                    set({ type: "", user: "", token: "" });
                },
            }),
            {
                name: 'app-storage',
                storage: createJSONStorage(() => sessionStorage),
            }
        )
    )
);
