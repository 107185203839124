import React, { useEffect, useState } from "react";
import axios from "axios";
import { MaterielCard } from "../../Components/Cards/MaterielCard";
import api from "../../store/api";

export const Materiel = () => {
    const [Materiel, setMateriel] = useState()
    const [isRefresh, setIsRefresh] = useState(false);

    const fetchMateriel = async () => {
        try {
            const response = await api.get(`/materiaux`);
            setMateriel((prev) => {
                return response.data
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchMateriel()
    }, [isRefresh]);

    const triggerRefresh = () => {
        setIsRefresh(!isRefresh);
    };

    return (
        <div className="flex flex-col w-4/5 justify-center items-center">
            <MaterielCard isButton onRefresh={triggerRefresh} />
            <MaterielCard items={Materiel} onRefresh={triggerRefresh} />
        </div>
    )
}