import React, { useState } from "react";
import { LoginInput } from "../../Components/Input/LoginInput";
import { LoginPswrdInput } from "../../Components/Input/LoginPswrdInput";
import { faCircleExclamation, faUser, faWarning } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useAppStore } from "../../store/user";
import { Popup } from "../../Components/Popup/Popup";

export const Login = () => {
    const [email, setEmail] = useState("");
    const [motDePasse, setMotDePasse] = useState("");
    const [error, setError] = useState(false)
    const [signal, setSignal] = useState("warning")

    const { login } = useAppStore(state => state);

    const navigate = useNavigate();

    const handleLogin = async () => {
        const response = await login(email, motDePasse);
        if (response.success) {
            navigate("/Produit");
        } else {
            if (response.status === 404) {
                setError(() => {
                    return "Utilisateur non trouvé!"
                });
                setSignal(() => {
                    return "warning"
                });
            } else if (response.status === 401) {
                setError(() => {
                    return "Mot de passe incorrect!"
                });
                setSignal(() => {
                    return "error"
                });
            }
            else {
                setError(() => {
                    return "Erreur de connexion!"
                });
                setSignal(() => {
                    return "error"
                });
            }
        }
    };


    return (
        <section className="gradient-form h-screen bg-neutral-200">
            <div className="container h-full p-10 max-sm:p-5">
                <div className="flex h-full flex-wrap items-center justify-center">
                    <div className="w-1/3 max-xl:w-1/2 max-md:w-2/3 max-sm:w-full">
                        <div className="block rounded-lg shadow-lg">
                            <div className="g-0 lg:flex lg:flex-wrap justify-center max-md:px-7">
                                <div className="w-full">
                                    <div className="md:p-7">
                                        <div className="text-center">
                                            <img
                                                className="mx-auto w-48"
                                                src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                                                alt="logo"
                                            />
                                            <h4 className="mb-7 mt-1 pb-1 text-xl font-semibold">
                                                We are The Lotus Team
                                            </h4>
                                        </div>
                                        <form>
                                            <p className="mb-4 text-neutral-600">Veuillez vous connecter à votre compte.</p>
                                            <LoginInput icon={faUser} id="user" placeholder="Adresse E-mail" state={(e) => setEmail(e.target.value)} />
                                            <LoginPswrdInput id="Mot_de_passe" placeholder="Mot de passe" state={(e) => setMotDePasse(e.target.value)} />
                                            <div className="mb-7 pb-1 pt-1 text-center">
                                                <button
                                                    className="mb-3 bg-sky-500 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-dark-3 transition duration-150 ease-in-out hover:shadow-dark-2 focus:shadow-dark-2 focus:outline-none focus:ring-0 active:shadow-dark-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                                                    type="button"
                                                    data-twe-ripple-init
                                                    data-twe-ripple-color="light"
                                                    onClick={handleLogin}
                                                >
                                                    Connexion
                                                </button>
                                                <a href="#!" className="text-sm">Mot de passe oublié?</a>
                                            </div>
                                            <div className="flex items-center justify-between max-sm:text-xs">
                                                <p className="mb-0 me-2">Pas de compte ?</p>
                                                <Link
                                                    to="/Inscription"
                                                    type="button"
                                                    className="inline-block rounded border-2 border-danger px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-danger-600 hover:bg-danger-50/50 hover:text-danger-600 focus:border-danger-600 focus:bg-danger-50/50 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-sky-500 dark:focus:bg-sky-500"
                                                    data-twe-ripple-init
                                                    data-twe-ripple-color="light"
                                                >
                                                    S'inscrire
                                                </Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {error && (
                <div>
                    <Popup signal={signal === "warning" ? faWarning : faCircleExclamation} message={error} />
                </div>)}
        </section>
    );
};
