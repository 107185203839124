import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Layout } from "./Layout";
import { Produit } from "./Page/Produit/Produit";
import { NouveauProduit } from "./Page/Produit/nouveauProduit";
import { NouveauMateriel } from "./Page/Materiel/NouveauMateriel";
import { Materiel } from "./Page/Materiel/Materiel";
import { ModificationMateriel } from "./Page/Materiel/ModificationMateriel";
import { ModificationProduit } from "./Page/Produit/ModificationProduit";
import { Commande } from "./Page/Commande/Commande";
import { NouveauCommande } from "./Page/Commande/NouveauCommande";
import { NouveauDetailCommande } from "./Page/Commande/NouveauDetailCommande";
import { ModificationCommande } from "./Page/Commande/ModificationCommande";
import { ModificationBesoinMateriaux } from "./Page/BesoinMateriel/ModificationBesoinMateriel";
import { Stock } from "./Page/Stock/Stock";
import { NouveauStock } from "./Page/Stock/NouveauStock";
import { ModificationStock } from "./Page/Stock/ModificationStock";
import { Login } from "./Page/Authentification/Login";
import { Inscription } from "./Page/Authentification/Inscription";
import { ForgotPasseword } from "./Page/Authentification/ForgotPasseword";
import { Home } from "./Page/Homepage";
import { useAppStore } from "./store/user";


function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/Inscription" element={<Inscription />} />
				<Route path="/ForgotPasseword" element={<ForgotPasseword />} />
				<Route path="/Home" element={<Layout />}>
					<Route index element={<Home />} />
				</Route>
				<Route path="/Produit" element={<Layout />}>
					<Route index element={<Produit />} />
					<Route path="Nouveau" element={<NouveauProduit />} />
					<Route path="Modification-Produit" element={<ModificationProduit />} />
				</Route>

				<Route path="/Materiel" element={<Layout />}>
					<Route index element={<Materiel />} />
					<Route path="Nouveau" element={<NouveauMateriel />} />
					<Route path="Modification-materiel" element={<ModificationMateriel />} />
				</Route>

				<Route path="/Commande" element={<Layout />}>
					<Route index element={<Commande />} />
					<Route path="Nouveau" element={<NouveauCommande />} />
					<Route path="Modification-Commande" element={<ModificationCommande />} />
					<Route path="NouveauDetail-Commande" element={<NouveauDetailCommande />} />
				</Route>

				<Route path="/BesoinMateriaux" element={<Layout />}>
					<Route index element={<ModificationBesoinMateriaux />} />
				</Route>

				<Route path="/Stock" element={<Layout />}>
					<Route index element={<Stock />} />
					<Route path="Nouveau" element={<NouveauStock />} />
					<Route path="Modification-Stock" element={<ModificationStock />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
