import React, { useEffect, useState } from "react";
import axios from "axios";
import { StockCard } from "../../Components/Cards/StockCard";
import api from "../../store/api";

export const Stock = () => {
    const [stock, setStock] = useState([]);
    const [isRefresh, setIsRefresh] = useState(false);

    const fetchStock = async () => {
        try {
            const response = await api.get(`/stock/getMaterielWithStock`);
            setStock(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchStock();
    }, [isRefresh]);

    const triggerRefresh = () => {
        setIsRefresh(!isRefresh);
    };

    return (
        <div className="flex flex-col w-3/4 justify-center items-center">
            <StockCard isButton onRefresh={triggerRefresh} />
            <StockCard items={stock} onRefresh={triggerRefresh} />
        </div>
    );
};
