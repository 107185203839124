
const colors = [
    { name: 'Blanc', code: '#ffffff' },
    { name: 'Noir', code: '#000000' },
    { name: 'Gris foncé', code: '#333333' },
    { name: 'Gris', code: '#808080' },
    { name: 'Gris clair', code: '#cccccc' },
    { name: 'Argent', code: '#c0c0c0' },
    { name: 'Bleu foncé', code: '#00008b' },
    { name: 'Bleu', code: '#0000ff' },
    { name: 'Bleu clair', code: '#add8e6' },
    { name: 'Bleu ciel', code: '#87ceeb' },
    { name: 'Turquoise', code: '#40e0d0' },
    { name: 'Vert foncé', code: '#006400' },
    { name: 'Vert', code: '#008000' },
    { name: 'Vert citron', code: '#32cd32' },
    { name: 'Jaune vert', code: '#9acd32' },
    { name: 'Jaune', code: '#ffff00' },
    { name: 'Or', code: '#ffd700' },
    { name: 'Marron', code: '#a52a2a' },
    { name: 'Brun', code: '#8b4513' },
    { name: 'Rouge foncé', code: '#8b0000' },
    { name: 'Rouge', code: '#ff0000' },
    { name: 'Rouge indien', code: '#cd5c5c' },
    { name: 'Rouge saumon', code: '#fa8072' },
    { name: 'Rose', code: '#ff69b4' },
    { name: 'Magenta', code: '#ff00ff' },
    { name: 'Violet', code: '#800080' },
    { name: 'Pourpre', code: '#a020f0' },
    { name: 'Indigo', code: '#4b0082' },
    { name: 'Orchidée', code: '#da70d6' },
    { name: 'Aubergine', code: '#6a0dad' },
    { name: 'Bisque', code: '#ffe4c4' },
    { name: 'Blé', code: '#f5deb3' },
    { name: 'Blanc antique', code: '#faebd7' },
    { name: 'Blanc floral', code: '#fffaf0' },
    { name: 'Bleu roi', code: '#4169e1' },
    { name: 'Bleu poudre', code: '#b0e0e6' },
    { name: 'Brun sable', code: '#f4a460' },
    { name: 'Champagne', code: '#fad6a5' },
    { name: 'Corail', code: '#ff7f50' },
    { name: 'Cyan', code: '#00ffff' },
    { name: 'Émeraude', code: '#50c878' },
    { name: 'Gainsboro', code: '#dcdcdc' },
    { name: 'Grenade', code: '#dc143c' },
    { name: 'Gris acier', code: '#d3d3d3' },
    { name: 'Ivoire', code: '#fffff0' },
    { name: 'Lavande', code: '#e6e6fa' },
    { name: 'Lavande pâle', code: '#dcd0ff' },
    { name: 'Lin', code: '#faf0e6' },
    { name: 'Magenta clair', code: '#ff77ff' },
    { name: 'Mauve', code: '#e0b0ff' },
    { name: 'Menthe', code: '#3eb489' },
    { name: 'Miel', code: '#f0fff0' },
    { name: 'Mousse', code: '#addfad' },
    { name: 'Noisette', code: '#deb887' },
    { name: 'Orchidée sombre', code: '#9932cc' },
    { name: 'Pêche', code: '#ffdab9' },
    { name: 'Perle', code: '#f0f0f0' },
    { name: 'Pêche pâle', code: '#ffe5b4' },
    { name: 'Pistache', code: '#93c572' },
    { name: 'Platine', code: '#e5e4e2' },
    { name: 'Pomme verte', code: '#7fff00' },
    { name: 'Pourpre foncé', code: '#4b0082' },
    { name: 'Rouge indien', code: '#cd5c5c' },
    { name: 'Sable', code: '#f4a460' },
    { name: 'Sable doré', code: '#f0e68c' },
    { name: 'Saphir', code: '#0f52ba' },
    { name: 'Sarcelle', code: '#008080' },
    { name: 'Sarcelle bleue', code: '#7fffd4' },
    { name: 'Saumon', code: '#fa8072' },
    { name: 'Saumon clair', code: '#ff9999' },
    { name: 'Sépia', code: '#704214' },
    { name: 'Teal', code: '#008080' },
    { name: 'Terre de Sienne', code: '#e97451' },
    { name: 'Terre d\'ombre brûlée', code: '#8a3324' },
    { name: 'Turquoise foncé', code: '#00ced1' },
    { name: 'Vanille', code: '#f3e5ab' },
    { name: 'Vert citron', code: '#32cd32' },
    { name: 'Vert forêt', code: '#228b22' },
    { name: 'Vert olive', code: '#6b8e23' },
    { name: 'Vert printemps', code: '#00ff7f' },
    { name: 'Vert chartreuse', code: '#7fff00' },
    { name: 'Vert clair', code: '#90ee90' },
    { name: 'Vert foncé', code: '#006400' },
    { name: 'Vert olive', code: '#556b2f' },
    { name: 'Vert printemps', code: '#00ff7f' },
    { name: 'Violet foncé', code: '#9400d3' },
    { name: 'Violet rougeâtre', code: '#c71585' },
];
export default colors;