import React, { useState } from "react";
import { LoginInput } from "../../Components/Input/LoginInput";
import { faVoicemail } from "@fortawesome/free-solid-svg-icons";

export const ForgotPasseword = () => {
    const [email, setEmail] = useState("")
    const handleSendEmail = () => {

    }
    return (
        <section className="gradient-form h-screen bg-neutral-200">
            <div className="container h-full p-10 max-sm:p-5">
                <div className="flex h-full flex-wrap items-center justify-center">
                    <div className="w-1/3 max-xl:w-1/2 max-md:w-2/3 max-sm:w-full">
                        <div className="block rounded-lg shadow-lg">
                            <div className="g-0 lg:flex lg:flex-wrap justify-center max-md:px-7">
                                <div className="w-full">
                                    <div className="md:p-7">
                                        <div className="text-center">
                                            <img
                                                className="mx-auto w-48"
                                                src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                                                alt="logo"
                                            />
                                            <h4 className="mb-7 mt-1 pb-1 text-xl font-semibold">
                                                We are The Lotus Team
                                            </h4>
                                        </div>
                                        <form>
                                            <LoginInput icon={faVoicemail} id="user" placeholder="Adresse E-mail" state={(e) => setEmail(e.target.value)} />
                                            <div className="mb-7 pb-1 pt-1 text-center">
                                                <button
                                                    className="mb-3 bg-sky-500 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-dark-3 transition duration-150 ease-in-out hover:shadow-dark-2 focus:shadow-dark-2 focus:outline-none focus:ring-0 active:shadow-dark-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                                                    type="button"
                                                    data-twe-ripple-init
                                                    data-twe-ripple-color="light"
                                                    onClick={handleSendEmail}
                                                >
                                                    Envoyer E-mail
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}