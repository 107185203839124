import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { OSButton } from "../../Components/Button/OSButton";
import { CheckBoxInput } from "../../Components/Input/CheckBoxInput";
import { OSInput } from "../../Components/Input/OSInput";
import { NumberInput } from "../../Components/Input/NumberInput";
import api from "../../store/api";

export const ModificationCommande = () => {
    const location = useLocation()
    const selectedItem = location.state.selectedItem
    const navigate = useNavigate();
    const [quantite, setQuantite] = useState(selectedItem.quantite)


    const ModifyCommande = async (e) => {
        e.preventDefault();
        try {
            const response = await api.put(`/detailsCommande/${selectedItem.detail_id}`, {
                quantite: quantite
            });
            navigate(`/Commande`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="w-1/2 mt-16 flex flex-col items-center justify-center">
            <div className="w-full h-1/6 py-3">
                <p className="text-3xl text-blue-950">Edit Commande</p>
            </div>
            <form onSubmit={ModifyCommande} className="w-2/3 rounded shadow-lg p-5 flex flex-col justify-center border border-gray-200">
                <div>
                    <OSInput
                        type="number"
                        placeholder="Quantité"
                        value={quantite}
                        onChange={(e) => setQuantite(e.target.value)}
                    />
                </div>
                <div className="flex justify-between items-center mt-8">
                    <button onClick={() => navigate("/Commande")} className="text-white bg-red-600 hover:bg-red-700 rounded-md px-4 py-2">
                        Annuler
                    </button>
                    <OSButton value="Modifier" />
                </div>
            </form>
        </div>
    );
};
