import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Navbar } from "../Components/NavBar/index";
import { useAppStore } from "../store/user";

export const Layout = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token, navigate]);

  return (
    <div className="flex flex-col w-screen overflow-x-hidden">
      <div className={`relative flex-1 flex-col w-full`}>
        <Navbar />
      </div>
      <div className="w-full md:ml-60">
        <Outlet />
      </div>
    </div>
  );
};
