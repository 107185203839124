import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { OSButton } from "../../Components/Button/OSButton";
import { OSInput } from "../../Components/Input/OSInput";
import colors from "../../Components/Color";
import api from "../../store/api";

export const NouveauCommande = () => {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [produits, setProduits] = useState([]);
    const navigate = useNavigate();

    const fetchProduits = async () => {
        try {
            const response = await api.get(`/produit/tous`);
            setProduits(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchProduits();
    }, []);

    const ajouterCommande = async (e) => {
        e.preventDefault();
        try {
            const produitsCommande = selectedProducts.map((productId) => ({
                produitId: productId,
                nom: produits.find((p) => p.produit_id === productId)?.nom,
                quantite: quantities[productId] || 0,
            }));
            const response = await api.post(`/Commande`, {
                produits: produitsCommande
            });
            navigate(`/Commande`);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                alert(error.response.data.message || "Erreur lors de la création de la commande.");
            } else {
                console.log(error);
            }
        }
    };

    const handleQuantityChange = (productId, value) => {
        const quantity = parseInt(value);

        setQuantities((prev) => ({
            ...prev,
            [productId]: quantity,
        }));

        if (quantity > 0) {
            if (!selectedProducts.includes(productId)) {
                setSelectedProducts((prev) => [...prev, productId]);
            }
        } else {
            setSelectedProducts((prev) => prev.filter((id) => id !== productId));
            setQuantities((prev) => {
                const newQuantities = { ...prev };
                delete newQuantities[productId];
                return newQuantities;
            });
        }
    };

    return (
        <div className="w-1/2 max-md:w-full mt-16 flex flex-col items-center justify-center">
            <div className="w-full h-1/6 py-3">
                <p className="text-3xl text-blue-950">Nouveau Commande</p>
            </div>
            <form onSubmit={ajouterCommande} className="w-2/3 max-md:w-72 rounded shadow-lg p-5 flex flex-col justify-center border border-gray-200">
                <div className="w-full">
                    {produits.map((produit, index) => (
                        <div key={index} className="flex items-center justify-center mb-4">
                            <p className="mr-3 w-20">{produit.nom} {produit.code}</p>
                            <OSInput
                                type="number"
                                placeholder="Quantité"
                                onChange={(e) => handleQuantityChange(produit.produit_id, e.target.value)}
                            />
                        </div>
                    ))}
                </div>
                <div className="flex justify-between items-center mt-8">
                    <button onClick={() => navigate("/Commande")} className="text-white bg-red-600 hover:bg-red-700 rounded-md px-4 py-2">
                        Annuler
                    </button>
                    <OSButton value="Ajouter" />
                </div>
            </form>
        </div>
    );
};
