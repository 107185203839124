import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

export const Popup = ({ signal, message }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 5000);

        return () => clearTimeout(timer)
    }, []);

    if (!isVisible) return null;

    return (
        <div className="absolute top-10 right-4 bg-gray-300 p-3 flex items-center space-x-2 rounded shadow-lg">
            <FontAwesomeIcon icon={signal} className={`${signal.iconName === 'triangle-exclamation' ? 'text-yellow-500' : "text-red-500"}`} />
            <p>{message}</p>
        </div>
    );
};
