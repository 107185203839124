import React, { useEffect, useState } from "react";
import { OSInput } from "../../Components/Input/OSInput";
import { OSButton } from "../../Components/Button/OSButton";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TextAreaInput } from "../../Components/Input/TextareaInput";
import colors from "../../Components/Color";
import { OSSelect } from "../../Components/Input/OSSelect";
import api from "../../store/api";

export const NouveauProduit = () => {
    const [Produit, setNomProduit] = useState("");
    const [description, setDescription] = useState("");
    const [quantities, setQuantities] = useState({});
    const [materiel, setMateriel] = useState([]);
    const [Prix, setPrix] = useState("");
    const [colorName, setColorName] = useState("");
    const [colorCode, setColorCode] = useState("");
    const navigate = useNavigate();

    const fetchMateriel = async () => {
        try {
            const response = await api.get(`/materiaux`);
            setMateriel(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchMateriel();
    }, []);

    const AjoutProduit = async (e) => {
        e.preventDefault();
        try {
            const materiauxData = Object.entries(quantities).map(([id, quantite]) => ({
                materiel_id: id,
                quantite
            }));

            await api.post(`/produit`, {
                nom: Produit,
                description: description,
                prix: Prix,
                materiaux: materiauxData,
                code: colorCode
            });

            navigate(`/Produit`);
        } catch (error) {
            console.log(error);
        }
    };

    const handleQuantityChange = (id, value) => {
        setQuantities((prev) => ({
            ...prev,
            [id]: parseInt(value),
        }));
    };

    const handleColorChange = async (selectedColor) => {
        const colorName = selectedColor.value;
        setColorName(colorName);
        const selectedColorData = colors.find(col => col.name === colorName);
        setColorCode(selectedColorData ? selectedColorData.code : "");
    };


    return (
        <div className="w-4/5 flex flex-col items-center justify-center">
            <div className="w-full h-1/6 py-3">
                <p className="text-3xl text-blue-950">Nouveau Produit</p>
            </div>
            <form onSubmit={AjoutProduit} className="w-2/3 rounded shadow-lg p-5 flex flex-col justify-center border border-gray-200">
                <div>
                    <OSInput placeholder="Nom" id="nom" state={(e) => setNomProduit(e.target.value)} />
                    <OSInput placeholder="Prix" type="number" id="Prix" state={(e) => setPrix(e.target.value)} />
                    <TextAreaInput label="Description du Produit" id="description" state={(e) => setDescription(e.target.value)} />
                    <div>
                        <div className="flex items-center mb-2 w-full">
                            <OSSelect
                                id={`couleur`}
                                items={colors}
                                onChange={handleColorChange}
                                label="Couleur :"
                            />
                        </div>
                        <p className="text-black/60">Besoin materiaux :</p>
                        {materiel.map((item) => (
                            <div key={item.materiel_id} className="flex items-center mb-4">
                                <p className="mr-3 w-52">{item.nom} {item.description}</p>
                                <OSInput
                                    type="number"
                                    placeholder="Quantité"
                                    value={quantities[item.materiel_id] || ""}
                                    onChange={(e) => handleQuantityChange(item.materiel_id, e.target.value)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-between items-center mt-16">
                    <button onClick={() => navigate('/Produit')} className="text-light bg-red-600 focus:ring-4 focus:ring-secondary font-medium rounded text-sm w-24 px-5 py-2 me-2 mb-2 focus:outline-none">Annuler</button>
                    <OSButton value="Ajouter" />
                </div>
            </form>
        </div>
    );
};
