import React, { useEffect, useState } from "react";
import axios from "axios";
import { CommandeCard } from "../../Components/Cards/CommandeCard";
import api from "../../store/api";

export const Commande = () => {
    const [commandeData, setCommandeData] = useState([]);
    const [isRefresh, setIsRefresh] = useState(false);

    const [commandes, setCommandes] = useState([]);

    useEffect(() => {
        const fetchCommandes = async () => {
            try {
                const response = await api.get(`/commande/commandeWithDetails`);
                setCommandes(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des commandes:", error);
            }
        };

        fetchCommandes();
    }, []);


    const triggerRefresh = () => {
        setIsRefresh(!isRefresh);
    };

    return (
        <div className="flex flex-col w-3/4 justify-center items-center">
            <CommandeCard isButton onRefresh={triggerRefresh} />
            <CommandeCard items={commandes} onRefresh={triggerRefresh} />
        </div>
    );
};
