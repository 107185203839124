import React from "react"

export const TextAreaInput = ({ label, id, state, defaultValue }) => {
    return (
        <div className="relative my-5 w-full" data-twe-input-wrapper-init>
            <textarea
                className="peer block min-h-[auto] w-full disabled:border-0 placeholder-shown:border-t-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm rounded-[7px] border-gray-200 focus:border-sky-500/80 placeholder-shown:border placeholder-shown:border-gray-200 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-sky-500/80 data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-black/90 dark:placeholder:text-neutral-300 dark:peer-focus:text-sky-500/80 [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                id={id}
                rows="4"
                placeholder={label}
                onChange={state}
                defaultValue={defaultValue}
            >
            </textarea>
            <label
                className="flex w-full h-full text-black/60 select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-sky-500/80 before:border-blue-gray-200 peer-focus:before:!border-sky-500/80 after:border-blue-gray-200 peer-focus:after:!border-sky-500/80">
                {label}
            </label>
        </div>
    )
}