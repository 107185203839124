import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { OSInput } from '../../Components/Input/OSInput';
import { OSButton } from '../../Components/Button/OSButton';
import api from '../../store/api';

export const ModificationBesoinMateriaux = () => {
    const location = useLocation();
    const selectedItem = location.state.selectedItem;
    const [Quantity, setQuantity] = useState("")
    const navigation = useNavigate();

    const ModifyMateriel = async (e) => {
        e.preventDefault();

        try {
            await api.put(`/besoinMateriaux/${selectedItem.besoin_id}`, {
                quantite: Quantity,
            });
            navigation(`/Produit`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="w-2/3 mt-16 flex flex-col items-center justify-center">
            <div className="w-full h-1/6 py-3">
                <p className="text-3xl text-blue-950">Edit besoin materiaux</p>
            </div>
            <form onSubmit={ModifyMateriel} className="w-2/3 rounded shadow-lg p-5 flex flex-col justify-center border border-gray-200">
                <div>
                    <OSInput
                        placeholder="Quantité"
                        type="number"
                        state={(e) => setQuantity(e.target.value)}
                    />
                </div>
                <div className="flex justify-between items-center mt-16">
                    <button type="button" onClick={() => navigation('/Produit')} className="text-black/80 bg-red-500 focus:ring-4 focus:ring-secondary font-medium rounded  w-24 px-5 py-2 me-2 mb-2 focus:outline-none">Annuler</button>
                    <OSButton value="Modifier" />
                </div>
            </form>
        </div>
    );
};
