import React, { useEffect, useState } from "react";
import { OSInput } from "../../Components/Input/OSInput";
import { OSButton } from "../../Components/Button/OSButton";
import { OSSelect } from "../../Components/Input/OSSelect";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import api from "../../store/api";

export const NouveauStock = () => {
    const [quantite, setQuantite] = useState("");
    const [materiel, setMateriel] = useState([]);
    const [selectedMateriel, setSelectedMateriel] = useState("");
    const navigate = useNavigate();

    const fetchMateriel = async () => {
        try {
            const response = await api.get(`/materiaux`);
            setMateriel(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchMateriel();
    }, []);

    const AjoutStock = async (e) => {
        e.preventDefault();
        try {
            await api.post(`/Stock`, {
                quantite: quantite,
                materiel_id: selectedMateriel,
            });
            navigate(`/Stock`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="w-4/5 flex flex-col items-center justify-center">
            <div className="w-full h-1/6 py-3">
                <p className="text-3xl text-blue-950">Nouveau Stock</p>
            </div>
            <form onSubmit={AjoutStock} className="w-2/3 rounded shadow-lg p-5 flex flex-col justify-center border border-gray-200">
                <div>
                    <OSSelect
                        id={`materiel`}
                        items={materiel}
                        onChange={(selectedOption) => setSelectedMateriel(selectedOption.value)}
                        label="Materiel :"
                    />
                    <br />
                    <OSInput
                        type="number"
                        placeholder="Quantité"
                        value={quantite || ""}
                        onChange={(e) => setQuantite(e.target.value)}
                    />
                </div>
                <div className="flex justify-between items-center mt-16">
                    <button onClick={() => navigate('/Stock')} className="text-light bg-red-600 focus:ring-4 focus:ring-secondary font-medium rounded text-sm w-24 px-5 py-2 me-2 mb-2 focus:outline-none">Annuler</button>
                    <OSButton value="Ajouter" />
                </div>
            </form>
        </div>
    );
};
