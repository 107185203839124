import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../store/api";

export const MaterielCard = ({ items, isButton, onRefresh }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate()

    const handleEditClick = (item) => {
        setSelectedItem(item);
        if (selectedItem) {
            navigate("/Materiel/Modification-materiel", { state: { selectedItem } });
        }
    };

    useEffect(() => {
    }, [selectedItem]);

    const handleDeleteClicked = async (id) => {
        const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer ce materiel")
        if (confirmation) {
            try {
                await api.delete(`/materiaux/${id}`)
                onRefresh();
            } catch (error) {
                console.log(error)
            }
        }
    };
    return (
        <div className="w-4/5 ml-11">
            {!isButton ? (
                <table className="table-auto w-full">
                    <thead className="justify-between text-left border-b border-gray-300">
                        <tr>
                            <th className="py-2">
                                <span className="text-gray-800">Materiel</span>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items?.map((item, index) => (
                            <tr key={index} className="bg-white border-b border-gray-300">
                                <td>
                                    <span className="text-black/90">{item.nom}</span>
                                </td>
                                <td className=" py-2">
                                    <span className="text-black/70">{item.description}</span>
                                </td>
                                <td className="py-2">
                                    <button
                                        className="text-green-700/80"
                                        onClick={() => handleEditClick(item)}
                                    >
                                        <FontAwesomeIcon icon={faEdit} color="green" />
                                    </button>
                                </td>
                                <td className="py-2">
                                    <button
                                        className="text-green-700/80"
                                        onClick={() => handleDeleteClicked(item.materiel_id)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} color="red" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="flex justify-end w-full my-5">
                    <Link to={`/Materiel/Nouveau`}>
                        <button className="w-40 py-2 px-4 font-semibold text-gray-50 rounded bg-sky-500">Ajouter Materiel</button>
                    </Link>
                </div>
            )}
        </div>
    )
}