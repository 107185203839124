import React from "react";
import Select from "react-select";

export const OSSelect = ({ id, items, onChange, label, error }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid rgba(0,0,0,0.7)" : "1px solid #ccc",
      boxShadow: state.isFocused ? "0 0 5px rgba(0,0,0,0.5)" : "none",
      "&:hover": {
        border: "1px solid rgba(0,0,0,0.5)"
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "white" : state.isFocused ? "lightblue" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "lightblue",
        color: "white"
      }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black"
    })
  };

  const options = items.map((item) => ({
    value: item.materiel_id || item.name,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: item.code,
            marginRight: "5px"
          }}
        ></span>
        {item.nom || item.name} {item.description}
      </div>
    )
  }));

  return (
    <div className="grid grid-cols-3 gap-x-2 w-full justify-center items-center mt-2">
      <Select
        id={id}
        options={options}
        styles={customStyles}
        onChange={onChange}
        placeholder={label}
        className="w-40"
      />
      <p className="text-xs text-red-500">{error?.message}</p>
    </div>
  );
};
