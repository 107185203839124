import React, { useEffect, useState } from "react";
import axios from "axios";
import { ProduitCard } from "../../Components/Cards/ProduitCard";
import api from "../../store/api";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../store/user";

export const Produit = () => {
    const [produit, setProduit] = useState()
    const [isRefresh, setIsRefresh] = useState(false);
    const token = useAppStore((state) => state.token);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
    }, [token, navigate]);

    const fetchProduit = async () => {
        try {
            const response = await api.get(`/produit`);
            setProduit((prev) => {
                return response.data
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchProduit()
    }, [isRefresh]);

    const triggerRefresh = () => {
        return setIsRefresh(!isRefresh);
    };

    return (
        <div className="flex flex-col w-3/4 max-md:w-full justify-center items-center">
            <ProduitCard isButton onRefresh={triggerRefresh} />
            <ProduitCard items={produit} onRefresh={triggerRefresh} />
        </div>
    )
}