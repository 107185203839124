import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faBars, faLayerGroup, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStore } from "../../store/user";

export const Navbar = () => {
    const [select, setSelect] = useState(false)
    const navigation = useNavigate()
    const { pathname } = useLocation()
    const [iseMenuVisible, setIsMenuVisible] = useState(false)
    const logout = useAppStore.use.logout()

    const handleNavItemClick = (href) => {
        navigation(`${href}`)
        setIsMenuVisible(false)
    };

    const SelectOnClick = () => {
        setSelect(!select)
    }

    const handleDeconnexion = async () => {
        logout()
        navigation("/")
    }

    const menuclick = () => {
        setIsMenuVisible(!iseMenuVisible);
    }

    return (
        <div>
            <header className={`md:hidden px-4 flex justify-between border-b border-black/10 shadow-md `}>
                <div>
                    <img className="h-10 w-10"
                        src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                        alt="logo"
                    />
                </div>
                <button onClick={menuclick}>
                    <FontAwesomeIcon icon={faBars} className="text-black/60" />
                </button>
            </header>
            <aside className={`w-52 z-10 border-r fixed border-gray-300 h-screen flex flex-col items-center pb-2 space-y-7 ${iseMenuVisible ? 'max-md:left-0' : 'max-md:left-[-208px]'} transition-all duration-300 max-md:bg-white`}>
                <div className="w-full flex justify-start pl-4 max-md:hidden max-md:h-0">
                    <img className="h-10 w-10"
                        src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                        alt="logo"
                    />
                </div>
                <div className="lg:hidden w-full flex justify-end px-3">
                    <button onClick={menuclick}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                <div className="w-full pr-3 flex flex-col gap-y-1 text-gray-500 fill-gray-500 text-sm">
                    <div className="font-QuicksandMedium pl-4 text-gray-400/60 text-xs text-[11px] uppercase">Menu</div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "/" ? 'bg-gray-200' : ''}`}
                        onClick={() => handleNavItemClick("/Home")}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/" ? 'fill-thirdly' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"></path>
                        </svg>
                        <span className={`font-QuicksandMedium ${pathname === "/" ? 'text-thirdly' : ''}`}>Acceuil</span>
                    </div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "/Produit" ? 'bg-gray-200' : ''}`}
                        onClick={() => handleNavItemClick("/Produit")}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/Produit" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <FontAwesomeIcon icon={faLayerGroup} className={`transition-colors duration-200 hover:text-thirdly ${pathname === "/Produit" ? 'text-thirdly' : 'text-gray-500'}`} />
                        <span className={`font-QuicksandMedium ${pathname === "/Produit" ? 'text-thirdly' : ''}`}>Produit</span>
                    </div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "Materiel" ? 'bg-gray-200' : ''}`}
                        onClick={() => handleNavItemClick("/Materiel")}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/Materiel" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/Materiel" ? 'fill-thirdly' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M9 3C6.23858 3 4 5.23858 4 8C4 10.7614 6.23858 13 9 13C11.7614 13 14 10.7614 14 8C14 5.23858 11.7614 3 9 3ZM3 21V20C3 17.7909 4.79086 16 7 16H10C12.2091 16 14 17.7909 14 20V21H3ZM16.42 14.23C14.88 13.48 12.99 13 12 13C8.69 13 6 15.69 6 19H16C16 17.98 16.21 16.96 16.42 14.23ZM17 8C17 9.6572 17.2102 11.2899 17.52 12.47C17.72 13.2 18.34 14 19.21 14H20C21.1046 14 22 14.8954 22 16V20C22 21.1046 21.1046 22 20 22H15V21H20C20.5523 21 21 20.5523 21 20V16C21 15.4477 20.5523 15 20 15H19.21C18.53 15 17.86 14.63 17.52 14.03C17.3102 13.71 17.17 13.35 17.11 13C17.0705 12.6941 17.0083 12.3698 16.92 12.03C16.83 11.69 16.71 11.33 16.58 10.96C16.3 10.18 15.71 9.55 15 9.16V9C15.5523 9 16 8.55228 16 8Z"></path>
                        </svg>
                        <span className={`font-QuicksandMedium ${pathname === "/Materiel" ? 'text-thirdly' : ''}`}>Materiel</span>
                    </div>
                    <div>
                        <div
                            className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "/Commande" ? 'bg-gray-200' : ''}`}
                            onClick={() => handleNavItemClick("/Commande")}
                        >
                            <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                                <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/Commande" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                            </div>
                            <svg className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/Commande" ? 'fill-thirdly' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g>
                                    <path d="M19 2H9c-1.11 0-2 .89-2 2v5.586l-4.707 4.7v0c-.4.39-.4 1.02 0 1.41 .18.18.44.29.7.29v5 0c0 .55.44 1 1 1h16v0c.55 0 1-.45 1-1v-17c0-1.11-.9-2-2-2Zm-8 18H5v-5.586l3-3 3 3V20Zm8 0h-6v-4 0c.55 0 .99-.45 1-1 0-.27-.11-.53-.3-.72L8.99 9.57V3.984h10v16Z"></path>
                                    <path d="M11 6h2v2h-2Zm4 0h2v2h-2Zm0 4.03h2v1.96h-2Zm0 3.96h2v2h-2Zm-8 1h2v2H7Z"></path>
                                </g>
                            </svg>
                            <span className={`font-QuicksandMedium ${pathname === "/Commande" ? 'text-thirdly' : ''}`}>Commande</span>
                        </div>
                    </div>
                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "Stock" ? 'bg-gray-200' : ''}`}
                        onClick={() => handleNavItemClick("/Stock")}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/Stock" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/Stock" ? 'fill-thirdly' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M9 3C6.23858 3 4 5.23858 4 8C4 10.7614 6.23858 13 9 13C11.7614 13 14 10.7614 14 8C14 5.23858 11.7614 3 9 3ZM3 21V20C3 17.7909 4.79086 16 7 16H10C12.2091 16 14 17.7909 14 20V21H3ZM16.42 14.23C14.88 13.48 12.99 13 12 13C8.69 13 6 15.69 6 19H16C16 17.98 16.21 16.96 16.42 14.23ZM17 8C17 9.6572 17.2102 11.2899 17.52 12.47C17.72 13.2 18.34 14 19.21 14H20C21.1046 14 22 14.8954 22 16V20C22 21.1046 21.1046 22 20 22H15V21H20C20.5523 21 21 20.5523 21 20V16C21 15.4477 20.5523 15 20 15H19.21C18.53 15 17.86 14.63 17.52 14.03C17.3102 13.71 17.17 13.35 17.11 13C17.0705 12.6941 17.0083 12.3698 16.92 12.03C16.83 11.69 16.71 11.33 16.58 10.96C16.3 10.18 15.71 9.55 15 9.16V9C15.5523 9 16 8.55228 16 8Z"></path>
                        </svg>
                        <span className={`font-QuicksandMedium ${pathname === "/Stock" ? 'text-thirdly' : ''}`}>Stock</span>
                    </div>
                </div>
                <div className="w-full pr-3 flex flex-col gap-y-1 text-gray-500 fill-gray-500 text-sm">

                    <div className="font-QuicksandMedium pl-4 text-gray-400/60 text-xs text-[11px] uppercase">Profile</div>

                    <div className="w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded-lg">
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className="absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-red-600 transition-all duration-300"></div>
                        </div>
                        <div className="group-hover:bg-white/10 w-full group-active:scale-95 self-stretch pl-2 rounded flex items-center space-x-2 transition-all duration-200 dark:group-hover:text-white dark:hover:text-white text-sm" href="#">
                            <svg className="h-5 w-5 group-hover:fill-red-600 dark:fill-gray-600  transition-colors duration-200" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8199 22H10.1799C9.71003 22 9.30347 21.673 9.20292 21.214L8.79592 19.33C8.25297 19.0921 7.73814 18.7946 7.26092 18.443L5.42392 19.028C4.97592 19.1709 4.48891 18.9823 4.25392 18.575L2.42992 15.424C2.19751 15.0165 2.27758 14.5025 2.62292 14.185L4.04792 12.885C3.98312 12.2961 3.98312 11.7019 4.04792 11.113L2.62292 9.816C2.27707 9.49837 2.19697 8.98372 2.42992 8.576L4.24992 5.423C4.48491 5.0157 4.97192 4.82714 5.41992 4.97L7.25692 5.555C7.50098 5.37416 7.75505 5.20722 8.01792 5.055C8.27026 4.91269 8.52995 4.78385 8.79592 4.669L9.20392 2.787C9.30399 2.32797 9.71011 2.00049 10.1799 2H13.8199C14.2897 2.00049 14.6958 2.32797 14.7959 2.787L15.2079 4.67C15.4887 4.79352 15.7622 4.93308 16.0269 5.088C16.2742 5.23078 16.5132 5.38736 16.7429 5.557L18.5809 4.972C19.0286 4.82967 19.515 5.01816 19.7499 5.425L21.5699 8.578C21.8023 8.98548 21.7223 9.49951 21.3769 9.817L19.9519 11.117C20.0167 11.7059 20.0167 12.3001 19.9519 12.889L21.3769 14.189C21.7223 14.5065 21.8023 15.0205 21.5699 15.428L19.7499 18.581C19.515 18.9878 19.0286 19.1763 18.5809 19.034L16.7429 18.449C16.5103 18.6203 16.2687 18.7789 16.0189 18.924C15.7567 19.0759 15.4863 19.2131 15.2089 19.335L14.7959 21.214C14.6954 21.6726 14.2894 21.9996 13.8199 22ZM11.9959 8C9.78678 8 7.99592 9.79086 7.99592 12C7.99592 14.2091 9.78678 16 11.9959 16C14.2051 16 15.9959 14.2091 15.9959 12C15.9959 9.79086 14.2051 8 11.9959 8Z"></path>
                            </svg>
                            <span className={`font-QuicksandMedium ${pathname === 5 ? 'text-thirdly' : ''}`}>Settings</span>
                        </div>
                    </div>

                    <div
                        className={`w-full flex items-center gap-x-1.5 group select-none hover:bg-gray-200 rounded ml-3 ${pathname === "Stock" ? 'bg-gray-200' : ''}`}
                        onClick={handleDeconnexion}
                    >
                        <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                            <div className={`absolute top-0 left-0 w-full h-[102%] translate-y-full group-hover:translate-y-0 bg-thirdly transition-all duration-300  ${pathname === "/Stock" ? 'translate-y-0 bg-thirdly' : ''}`}></div>
                        </div>
                        <svg className={`h-5 w-5 transition-colors duration-200 group-hover:fill-thirdly ${pathname === "/Stock" ? 'fill-thirdly' : 'text-gray-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M9 3C6.23858 3 4 5.23858 4 8C4 10.7614 6.23858 13 9 13C11.7614 13 14 10.7614 14 8C14 5.23858 11.7614 3 9 3ZM3 21V20C3 17.7909 4.79086 16 7 16H10C12.2091 16 14 17.7909 14 20V21H3ZM16.42 14.23C14.88 13.48 12.99 13 12 13C8.69 13 6 15.69 6 19H16C16 17.98 16.21 16.96 16.42 14.23ZM17 8C17 9.6572 17.2102 11.2899 17.52 12.47C17.72 13.2 18.34 14 19.21 14H20C21.1046 14 22 14.8954 22 16V20C22 21.1046 21.1046 22 20 22H15V21H20C20.5523 21 21 20.5523 21 20V16C21 15.4477 20.5523 15 20 15H19.21C18.53 15 17.86 14.63 17.52 14.03C17.3102 13.71 17.17 13.35 17.11 13C17.0705 12.6941 17.0083 12.3698 16.92 12.03C16.83 11.69 16.71 11.33 16.58 10.96C16.3 10.18 15.71 9.55 15 9.16V9C15.5523 9 16 8.55228 16 8Z"></path>
                        </svg>
                        <span className={`font-QuicksandMedium ${pathname === "/Stock" ? 'text-black' : ''}`}>Deconnexion</span>
                    </div>
                </div>
            </aside>
        </div>
    )
}