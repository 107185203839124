import React, { useState } from "react";
import { OSInput } from "../../Components/Input/OSInput";
import { OSButton } from "../../Components/Button/OSButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { TextAreaInput } from "../../Components/Input/TextareaInput";
import api from "../../store/api";

export const ModificationProduit = () => {
    const location = useLocation()
    const selectedItem = location.state.selectedItem
    const [Produit, setNomProduit] = useState(selectedItem.nom);
    const [description, setDescription] = useState(selectedItem.description)
    const [Prix, setPrix] = useState(selectedItem.prix)
    const navigation = useNavigate()

    const ModifyProduit = async (e) => {
        e.preventDefault();

        try {
            await api.put(`/produit/${selectedItem.nom}`, {
                nom: Produit,
                description: description,
                prix: Prix,
            });
            navigation(`/Produit`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="w-1/2 mt-16 max-md:w-full flex flex-col items-center justify-center">
            <div className="w-full h-1/6 py-3">
                <p className="text-3xl text-blue-950">Edit Produit</p>
            </div>
            <form onSubmit={ModifyProduit} className="w-2/3 max-md:w-11/12 rounded shadow-lg p-5 flex flex-col justify-center border border-gray-200">
                <div>
                    <OSInput placeholder="Nom" id="nom" state={(e) => setNomProduit(e.target.value)} defaultValue={selectedItem.nom} />
                    <OSInput placeholder="Prix" id="Prix" state={(e) => setPrix(e.target.value)} defaultValue={selectedItem.prix} />
                    <TextAreaInput label="Description du Produit" id="description" state={(e) => setDescription(e.target.value)} defaultValue={selectedItem.description} />
                </div>
                <div className="flex justify-between items-center mt-16">
                    <button onClick={() => navigation('/Produit')} className="text-light bg-red-600 focus:ring-4 focus:ring-secondary font-medium rounded  w-24 px-5 py-2 me-2 mb-2 focus:outline-none">Annuler</button>
                    <OSButton value="Modifier" />
                </div>
            </form>
        </div>
    );
};