import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import api from "../../store/api";

export const StockCard = ({ items, isButton, onRefresh }) => {
    const navigate = useNavigate();

    const handleEditClick = (item) => {
        navigate("/Stock/Modification-Stock", { state: { selectedItem: item } });
    };

    const handleDeleteClicked = async (id) => {
        const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer ce stock ?");
        if (confirmation) {
            try {
                await api.delete(`/stock/${id}`);
                onRefresh();
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className="w-full ml-11">
            {!isButton ? (
                <table className="table-auto w-full">
                    <thead className="justify-between text-left border-b border-gray-300">
                        <tr>
                            <th className="py-2">
                                <span className="text-gray-800">Materiel</span>
                            </th>
                            <th className="py-2">
                                <span className="text-gray-800">Stock</span>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items?.map((item, index) => (
                            <tr className="bg-white border-b border-gray-300" key={index}>
                                <td>
                                    <span className="text-black/90">{item.nom} {item.description}</span>
                                </td>
                                <td className="py-2">
                                    <span className="text-black/70">{parseInt(item.quantite)}</span>
                                </td>
                                <td className="py-2">
                                    <button
                                        className="text-green-700/80"
                                        onClick={() => handleEditClick(item)}
                                    >
                                        <FontAwesomeIcon icon={faEdit} color="green" />
                                    </button>
                                </td>
                                <td className="py-2">
                                    <button
                                        className="text-green-700/80"
                                        onClick={() => handleDeleteClicked(item.stock_id)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} color="red" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="flex justify-end w-full my-5">
                    <Link to={`/Stock/Nouveau`}>
                        <button className="w-40 py-2 px-4 font-semibold text-gray-50 rounded bg-sky-500">Ajouter Stock</button>
                    </Link>
                </div>
            )}
        </div>
    );
};
