import React from "react";

export const Home = () => {
    return (
        <div>
            <div>
                <h1>Lotus Team</h1>
            </div>
        </div>
    )
}