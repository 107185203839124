import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { CommandeDetailCard } from "./CommandeDetailCard";
import api from "../../store/api";

export const CommandeCard = ({ items, isButton, onRefresh }) => {
    const navigate = useNavigate();
    const [commandeStates, setCommandeStates] = useState({});
    const [insufficientQuantities, setInsufficientQuantities] = useState({});

    const handleAjoutDetailClicked = (item) => {
        navigate("/Commande/NouveauDetail-Commande", { state: { selectedItem: item } });
    };

    const handleDeleteClicked = async (id) => {
        const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cette commande ?");
        if (confirmation) {
            try {
                await api.delete(`/commande/${id}`);
                onRefresh();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleDetailClicked = async (commandeId, detailId) => {
        setCommandeStates((prevState) => ({
            ...prevState,
            [commandeId]: {
                ...prevState[commandeId],
                [detailId]: !prevState[commandeId]?.[detailId],
            }
        }));

        if (!commandeStates[commandeId]?.[detailId]) {
            try {
                const response = await api.get(`/detailsCommande/insuffisants/${detailId}`);
                setInsufficientQuantities((prevState) => ({
                    ...prevState,
                    [detailId]: response.data,
                }));
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className="w-full ml-11 flex justify-center items-center">
            {!isButton ? (
                <div className="container mx-auto p-4 flex justify-start gap-x-20 flex-wrap items-center">
                    {items.map(commande => (
                        <div key={commande.commande_id} className="border py-4 mb-4 rounded-lg shadow-md w-96">
                            <h2 className="pl-4 text-xl font-bold">Commande {commande.commande_id}</h2>
                            <div className="mt-2">
                                {commande.detailsCommande.map(detail => (
                                    <div key={detail.detail_id} className="border-t p-2 flex flex-col">
                                        <div className="flex justify-between items-center">
                                            <div className="">
                                                <p><strong>Nom:</strong> {detail.nom}</p>
                                                <p><strong>Quantité:</strong> {detail.quantite}</p>
                                            </div>
                                            <button
                                                className="text-green-700/80 mt-2"
                                                onClick={() => handleDetailClicked(commande.commande_id, detail.detail_id)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={commandeStates[commande.commande_id]?.[detail.detail_id] ? faEye : faEyeSlash}
                                                    size={25}
                                                />
                                            </button>
                                        </div>
                                        {commandeStates[commande.commande_id]?.[detail.detail_id] && (
                                            <div className="mt-2 border-t pt-2">
                                                {insufficientQuantities[detail.detail_id]?.length ? (
                                                    insufficientQuantities[detail.detail_id].map(insuff => (
                                                        <div key={insuff.notEngouh_id} className="p-2">
                                                            <p><strong>Nom:</strong> {insuff.Materiel.nom}</p>
                                                            <p><strong>Quantité insuffisante:</strong> {insuff.nombre}</p>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p>Aucune quantité insuffisante.</p>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                ))}
                            </div>
                            <div className="flex justify-between mt-4 px-4">
                                <button
                                    className="text-red-700/80"
                                    onClick={() => handleAjoutDetailClicked(commande.commande_id)}
                                >
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v18m9-9H3" />
                                    </svg>
                                </button>
                                <button
                                    className="text-red-700/80"
                                    onClick={() => handleDeleteClicked(commande.commande_id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} color="red" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex justify-end w-full my-5">
                    <Link to={`/Commande/Nouveau`}>
                        <button className="w-52 py-2 px-4 font-semibold text-gray-50 rounded bg-sky-500">Ajouter Commande</button>
                    </Link>
                </div>
            )}
        </div>
    );
};
